.button {
  --font-size: 14px;
  --border-color: var(--button-border-color);
  --icon-size: 20px;
  --border-radius: 8px;
  --chevron-icon-size: 14px;
  --line-height: 20px;
  --gap: 8px;

  border: 1px solid var(--button-border-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: var(--gap);
  transition: 200ms;
  color: hsl(218, 24%, 27%);
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size);
  line-height: var(--line-height);
  background: hsl(0, 0%, 100%);
  text-decoration: none;
  font-variation-settings: 'wght' 400;
  border-radius: var(--border-radius);
  white-space: nowrap;
  font-family: var(--main-font);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.small {
    --icon-size: 16px;
    --font-size: 12px;
    --gap: 6px;
    --border-radius: 5px;
    --chevron-icon-size: 12px;
    --line-height: 17px;
    padding: 5px 8px;
  }

  &.hoverable {
    cursor: pointer;

    &:hover {
      background: var(--card-background-hover);
      border-color: var(--border-color);
      --stroke-width: 2;
    }
  }
  &.disabled {
    cursor: not-allowed;
    color: hsl(0, 0%, 90%);
    border-color: hsl(0, 0%, 90%);
  }

  &.largeCTA {
    font-size: 1.7em;
    letter-spacing: -0.015rem;
    padding: 1em 1.3em;
    --button-background: rgb(228, 148, 0);
    color: hsl(0, 0%, 100%);
    background: var(--button-background);
    border: none;
    font-weight: 600;
    position: relative;

    &:after {
      opacity: 0;
      transition: 300ms ease-in-out;
      content: '→';
      position: absolute;
      right: 15px;
    }

    &.hoverable:hover {
      background: hsl(226, 24%, 40%);
      border-color: transparent;
      padding: 1em 1.6em 1em 1em;

      &:after {
        opacity: .6;
        right: 10px;
      }
    }
  }

  &.primary {
    color: hsl(0, 0%, 100%);
    background: var(--button-background);
    border: 1px solid transparent;
    box-shadow: 0px 1px 2px hsla(220, 43%, 11%, 0.05);

    &.hoverable:hover {
      background: hsl(226, 24%, 40%);
      border-color: transparent;
    }
  }

  &.dangerous {
    color: hsl(218, 24%, 27%);
    border: 1px solid transparent;

    &.hoverable:hover {
      background: hsl(0, 58%, 81%);
      color: hsl(0, 100%, 38%);
      border-color: transparent;
    }
  }

  &.text {
    color: hsl(218, 24%, 27%);
    border: 1px solid transparent;

    &.hoverable:hover {
      background: hsla(210, 13%, 91%, 0.41);
      border: 1px solid transparent;
    }
  }

  &.active {
    color: hsl(218, 30%, 21%);
    background: hsl(220, 20%, 96%);
    font-variation-settings: 'wght' 600;
    letter-spacing: -0.019em;
  }

  &.dashed {
    border-style: dashed;
  }
}

.icon {
  min-width: var(--icon-size);
  height: var(--icon-size);
  display: block;
  flex-shrink: 0;

  svg {
    width: 100%;
    width: auto;
    height: 100%;
    display: block;
  }
}

.group {
  --border-radius: 8px;
  display: inline-flex;
  flex-direction: row;
  border: 1px solid var(--border-color);
  filter: drop-shadow(0px 1px 2px hsla(220, 43%, 11%, 0.05));
  overflow: hidden;
  flex-shrink: 0;
  border-radius: var(--border-radius);

  &.small {
    --border-radius: 5px;
  }

  .button {
    border: 1px solid transparent;
    border-radius: 0;
    border: none;

    &.hoverable {
      &:hover {
        border: none;
        border-color: transparent;
      }
    }

    &:first-child {
      border-left: none;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }
}

.groupDivider {
  background: var(--border-color);
  width: 1px;
  flex-shrink: 0;
  flex-grow: 0;
}

.chevron {
  width: var(--chevron-icon-size);
  height: var(--chevron-icon-size);
  margin: 0 -3px;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.dot {
  --size: 5px;
  margin-right: -3px;
  height: var(--size);
  width: var(--size);
  border-radius: 10em;
  background: hsl(220, 16%, 64%);

  &.errorDot {
    background: hsl(0, 100%, 50%);
    outline-offset: 2px;
  }
  &.successDot {
    background: hsl(120, 57%, 53%);
  }
  &.warningDot {
    background: hsl(58, 57%, 53%);
  }
}

.submitButtonIcon {
  height: 20px;
  width: 20px;
}

.orgButton {
  height: 34px;
  width: 34px;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: transparent;
  transition: 300ms;
  --stroke-width: 2px;

  &.active,
  &:hover {
    background: linear-gradient(97deg, #9c85df -1.71%, #7396d7 100%);

    .icon {
      color: #ffffff;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    color: rgba(125, 137, 176, 1);
  }
}

.authButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.animating:not(:disabled) {
  animation: pulse var(--animation-duration, 1.5s) infinite;
  --colorHighlight: #1d0646;
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.03);
    background: var(--colorHighlight);
    box-shadow: 0 0 4px rgba(#1c4164, 0.5);
  }
  100% {
    transform: scale(0.98);
    box-shadow: none;
  }
}
